import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import './assets/css/styles.css'
import './index.css'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import VueTailwind from 'vue-tailwind'
import {uiSettings} from './ui_settings'


Vue.config.productionTip = false
Vue.use(VueViewer)
Vue.use(VueTailwind, uiSettings)

new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')