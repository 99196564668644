<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <div class="px-2 md:px-4 lg:px-10 mx-auto w-full">
        <div class="flex flex-wrap pt-24">
          <div class="w-full mb-12 px-4">

            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
            >
              <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap justify-between items-center px-4">
                  <div class="flex items-center justify-start gap-2">
                    <table-dropdown class="flex justify-center"
                                    :actions="headActions(
                                        {
                                            subjects,
                                            functions: {
                                              convertToCSV, exportCSVFile
                                            }
                                          }
                                        )"
                                    icon="DotsCircleHorizontalIcon" />
                    <h3
                        class="font-semibold text-lg text-blueGray-700 capitalize"
                    >
                      {{ this.currentTable.url }} ({{this.subjects.length}})
                    </h3>
                  </div>
                  <div class="flex items-start justify-center lg:justify-end lg:items-center
                              flex-col lg:flex-row gap-2 lg:gap-4 w-full lg:w-auto mt-6 lg:mt-0">
                    <Button v-if="this.sorted" @wasClicked="resetSort()">
                      Reset sort
                    </Button>
                    <ButtonRouter widttt="" v-if="this.currentTable && this.currentTable.db
                    && this.currentTable.actionData && this.currentTable.actionData.entity"
                                  :href="{name: 'EntityEdit',
                                          params: {
                                          entityId: 'new',
                                          tableName: this.currentTable.url,
                                          dbName: this.currentTable.db,
                                        }}">
                      New
                    </ButtonRouter>
                    <span v-if="this.currentTable.actionData && this.currentTable.actionData.filter" class="text-sm">Filter</span>
                    <Input v-if="this.currentTable.actionData && this.currentTable.actionData.filter" type="date" v-model="dateFrom" width="140" />
                    <span v-if="this.currentTable.actionData && this.currentTable.actionData.filter" class="hidden lg:block">-</span>
                    <Input v-if="this.currentTable.actionData && this.currentTable.actionData.filter" type="date" v-model="dateTo" width="140" />
                  </div>
                </div>
              </div>
              <div v-if="subjects.length && !this.filtered"
                   class="block w-full overflow-x-auto">
                <!-- Projects table -->
                <table class="items-center w-full bg-transparent">
                  <thead>
                  <tr class="bg-blueGray-50">
                    <th class="px-0 align-middle border border-solid py-0 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left text-blueGray-500 border-blueGray-100"></th>
                    <th
                        v-for="(field, index) in showFields" :key="'field' + index"
                        @click="doSortColumn($event, index)"
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0
                        cursor-pointer select-none group
                        whitespace-nowrap font-semibold text-left text-blueGray-500 border-blueGray-100"
                        :class="{'inline-block': field.type === 'image','w-20': field.type === 'image'}"
                    >
                      <div class="flex items-center justify-start gap-2">
                        <span>
                          {{ field.verbose ? field.verbose : field.name }}
                        </span>
                        <ChevronUpIcon
                            :class="{
                              'opacity-0': !sorted,
                              'opacity-25': sorted && sortColumnKey!==index,
                              'text-gray-400': sorted && sortColumnKey!==index,
                              'wb-text-green': sorted && sortColumnKey===index,
                              'rotate-180': !sortDirection && sortColumnKey===index,
                            }"
                            class="group-hover:opacity-50 transform transition" />
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(subject, index) in subjects" :key="'subject' + index">
                    <td
                        class="border-t-0 px-0 align-top border-l-0 border-r-0 text-xs whitespace-nowrap p-0 text-right"
                    >
                      <table-dropdown class="flex justify-center"
                                      v-if="actions(subject).length"
                                      :actions="actions(subject)" />
                    </td>
                    <td v-for="(field, indexf) in showFields" :key="'sfield' + indexf"
                        :style="setCss(subject, field)"
                        :class="{ 'inline-block': field.type === 'image',
                                  'w-20': field.type === 'image',
                                  'whitespace-nowrap': field.type !== 'bigtext',
                                  'maxw500': field.type === 'bigtext'}"
                        class="border-t-0 px-6 py-4 Salign-middle border-l-0 border-r-0 text-xs align-top"
                    >
                      <img
                          :src="(field.prefix ? field.prefix : '') + subject[field.name]"
                          v-if="field.type === 'image'"
                          :class="{'bg-white': !field.background}"
                          :style="field.background ? 'background: ' + field.background : ''"
                          class="h-12 w-12 rounded-full border max-w-none"
                          alt="..."
                      />
                      <div v-else-if="field.type === 'text'">
                        {{ maxLen(textToJSON(subject, field), field.maxlen) }}
                      </div>
                      <div v-else-if="field.type === 'bigtext'">
                        {{ maxLen(textToJSON(subject, field), field.maxlen) }}
                      </div>
                      <div v-else-if="field.type === 'bool'">
                        {{ subject[field.name] === 0 || subject[field.name] === '' || subject[field.name] === null
                          ? 'False' : 'True' }}
                      </div>
                      <div v-else-if="field.type === 'id'">
                        {{ subject[field.name] }}
                      </div>
                      <a target="_blank" :href="youtube_url(subject[field.name])"
                         class="wb-text-blue" v-else-if="field.type === 'youtube_url'">
                        {{ youtube_url(subject[field.name]) }}
                      </a>
                      <a target="_blank" :href="(field.prefix ? field.prefix : '') + subject[field.name]"
                         class="wb-text-blue" v-else-if="field.type === 'url'">
                        {{ (field.prefix ? field.prefix : '') + subject[field.name] }}
                      </a>
                      <div v-else-if="field.type === 'select'">
                        {{ selectorToVerbose(selectors[field.select], subject[field.name]) }}
                      </div>
                      <div v-else-if="field.type === 'time'">
                        {{ timestampToDate(subject[field.name]) }}
                      </div>
                      <div v-else-if="field.type === 'timedeltadays'">
                        {{ timestampToDeltaDays(subject[field.name]) }} days ago
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <Loader v-else-if="(this.filtered || !this.filterEmpty) && !this.stopLoader" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";
import Input from "@/components/Inter/Input";
import Loader from "@/components/Block/Loader";
import ButtonRouter from "@/components/Inter/ButtonRouter";
import Button from "@/components/Inter/Button";
import {convertToCSV, exportCSVFile} from "@/helpers/json2csv";
import { listActions } from "@/helpers/userActions";
import { ChevronUpIcon } from "@vue-hero-icons/solid"
import nvgpost from "@/nvgpost";


export default {
  name: "Table",
  components: {
    ButtonRouter, Button,
    Input, Loader, ChevronUpIcon,
    Sidebar, AdminNavbar, TableDropdown
  },
  computed: {
    currentTable: function () {
      return this.$store.getters.currentTable
    },
    showFields: function () {
      const fields = this.currentTable.fields
      return fields ? fields.filter(function (x) {
        return !x.hidden
      }) : []
    },
    subjects: function () {
      return this.sorted ? this.sortedTable : this.nakeSubjects
    },
    nakeSubjects: function () {
      return this.$store.state.tableData0[this.currentTable.url] ? this.$store.state.tableData0[this.currentTable.url] : []
    },
    selectors: function () {
      return this.$store.state.selectors
    },
    headActions: function () {
      return function (data) {
        let heads = []

        heads.push({
          data: {
            name: "SettingsTable",
            params: {
              tableName: this.currentTable.url,
              dbName: this.currentTable.db
            }
          },
          click: "url",
          name: "Settings",
        })

        if(this.currentTable.actionTable) {
          if(this.currentTable.actionTable.exportCSV) {
            heads.push({
              data: {
                text: "Export data to .csv ?",
                yesFunc: function () {
                  const headers = Object.keys(data.subjects[0]).map((element) => {
                    return element.replace(/,/g, '')
                  })

                  const csv = data.functions.convertToCSV(data.subjects)
                  data.functions.exportCSVFile(headers, csv, this.currentTable.url)
                  this.$store.commit("toggleConfirm")
                }.bind(this)
              },
              click: "confirm",
              name: "Export CSV (current table)",
            })

            heads.push({
              data: {
                text: "Export data to .csv ?",
                yesFunc: async function () {
                  await nvgpost(this.$apiEndpoint+"/v1/table_data",
                      "POST",
                      {csv: this.currentTable.name},
                      {}, async (res)=>{
                        if(res.data) {
                          const headers = Object.keys(res.data.csv[0]).map((element) => {
                            return element.replace(/,/g, '')
                          })
                          const csv = data.functions.convertToCSV(res.data.csv)
                          data.functions.exportCSVFile(headers, csv, this.currentTable.url)
                          this.$store.commit("toggleConfirm")
                        }
                        else {
                          this.$root.$emit("alert", "Table get error", 2)
                        }
                      }, true)
                }.bind(this)
              },
              click: "confirm",
              name: "Export CSV (full data)",
            })
          }

          if(this.currentTable.actionTable.exportGoogle) {
            heads.push({
              data: {
                text: "Export data to Google Sheet ?",
                yesFunc: function () {

                  nvgpost(this.$apiEndpoint+"/v1/table_export",
                      "POST",
                      {
                        point: this.currentTable
                      },
                      {}, (res)=>{
                        if(res.data) {
                          this.$root.$emit("alert", "Export was successfull!", 1)
                        }
                      }, true)


                  this.$store.commit("toggleConfirm")
                }.bind(this)
              },
              click: "confirm",
              name: "Export Google Sheet (full data)",
            })
          }
        }

        return heads
      }
    },
    actions: function () {
      return function (subject) {
        return listActions(this, subject)
      }
    },
  },
  data() {
    return {
      stopLoader: false,
      nakedchange: 0,
      dateFrom: "",
      dateTo: "",
      filtered: false,
      filterEmpty: false,
      sorted: false,
      sortDirection: false,
      sortColumnKey: "",
      sortedTable: false,
    }
  },
  methods: {
    convertToCSV: function (obj) {
      return convertToCSV(obj)
    },
    exportCSVFile: function (headers, csv, fileTitle) {
      return exportCSVFile(headers, csv, fileTitle)
    },
    textToJSON: function (subject, field) {
      return (field.dictkey && subject[field.name]
          ? ((subject[field.name][field.dictkey]) ? subject[field.name][field.dictkey]
              : subject[field.name][Object.keys(subject[field.name])[0]])
          : subject[field.name])
    },
    maxLen: function (text, len) {
      if(text && len) {
        return text.slice(0, len)
      }
      return text
    },
    youtube_url: function (code) {
      const youtube = "https://youtu.be/"
      const youtube2 = "https://www.youtube.com/embed/"
      return code.indexOf(youtube) !== -1 ? code
          : (code.indexOf(youtube2) !== -1 ? code.replace(youtube2, youtube)
              : youtube + code)
    },
    resetSort: function () {
      this.sorted = false
    },
    doSortColumn: function (event, key) {
      this.sorted = true
      this.sortDirection = !this.sortDirection

      const sortColumn = this.showFields[key].name
      this.sortColumnKey = key
      this.sortedTable = [...this.nakeSubjects]
      this.sortedTable = this.sortedTable.sort((a,b) => {
        let sortedValue = a[sortColumn] - b[sortColumn]
        if(a[sortColumn] === undefined) {
          a[sortColumn] = ""
        }
        if(b[sortColumn] === undefined) {
          b[sortColumn] = ""
        }

        if(isNaN(sortedValue))
          sortedValue = a[sortColumn].toString().localeCompare(b[sortColumn].toString())
        return sortedValue
      })
      if(!this.sortDirection)
        this.sortedTable = this.sortedTable.reverse()
    },
    timestampToDate: function (unix_timestamp) {
      const date = new Date(unix_timestamp * 1000)
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const hours = ("0" + date.getHours()).slice(-2)
      const minutes = ("0" + date.getMinutes()).slice(-2)
      //const seconds = "0" + date.getSeconds()
      const year = date.getFullYear()
      //const month = date.getUTCMonth() + 1
      const month = monthNames[date.getUTCMonth()]
      const day = ("0"+date.getUTCDate()).slice(-2)
      return `${month} ${day}, ${year} ${hours}:${minutes}`
    },
    setCss: function (subject, field) {
      if(field.css) {
        try {
          let jsonCSS = JSON.parse(field.css)
          if(field.name !== undefined && subject[field.name] !== undefined) {
            let fieldCss = []
            for (let i in jsonCSS) {
              if(eval(jsonCSS[i].if.replace("(","").replace(")",""))) {
                fieldCss.push(jsonCSS[i].css)
              }
            }
            return fieldCss.join("")
          }

        } catch (e) {
          return field.css
        }

        return field.css
      }
      return ""
    },
    timestampToDeltaDays: function (unix_timestamp) {
      const date = new Date(unix_timestamp * 1000)
      const today = new Date()
      return Math.ceil((today - date + 1) / 86400000);
    },
    selectorToVerbose: function (selector, name) {
      if(selector !== undefined) {
        const result = selector.filter(function (x) {
          return x.name !== undefined && name !== undefined && x.name.toString() === name.toString()
        })[0]

        if(result && result.verbose) {
          return result.verbose
        }
      }
      return ""
    }
  },
  mounted() {
    this.$store.commit("toggleTableDataPage")
    setTimeout(function () {
      if(!this.$store.state.tableDataPage) {
        this.$store.commit("toggleTableDataPage")
      }
    }.bind(this), 500)
  },
  watch: {
    nakeSubjects: function () {
      this.nakedchange++

      setTimeout(function () {
        if(this.nakedchange === 1 || this.nakedchange === 2) {
          this.stopLoader = true
        }
      }.bind(this), 2500)
    },
    currentTable: function (newValue, oldValue) {
      if(oldValue.name === undefined) {
        this.$store.dispatch("getTableData", {
          table: newValue.name,
          output: newValue.url,
          that: this,
        })
      }
    },
    dateFrom: async function (newValue) {
      this.filtered = true
      await this.$store.dispatch("getTableData", {
        table: this.currentTable.name,
        output: this.currentTable.url,
        that: this,
        filter: {
          from: newValue,
          to: this.dateTo,
          column: this.currentTable.actionData.filter.by
        }
      })
      this.filterEmpty = !this.subjects.length
      this.filtered = false
    },
    dateTo: async function (newValue) {
      this.filtered = true
      await this.$store.dispatch("getTableData", {
        table: this.currentTable.name,
        output: this.currentTable.url,
        that: this,
        filter: {
          from: this.dateFrom,
          to: newValue,
          column: this.currentTable.actionData.filter.by
        }
      })
      this.filterEmpty = !this.subjects.length
      this.filtered = false
    }
  },
}
</script>

<style scoped>
  .maxw500 {
    max-width: 500px;
  }
</style>