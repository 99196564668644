<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <GoogleDashboard v-if="isStudio" :contentModule="contentModule" />
      <WeboaDashboard v-else :contentModule="contentModule" />
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import GoogleDashboard from "@/layouts/GoogleDashboard";
import WeboaDashboard from "@/layouts/WeboaDashboard";


export default {
  name: 'Dashboard',
  components: {
    WeboaDashboard,
    GoogleDashboard,
    AdminNavbar,
    Sidebar
  },
  computed: {
    currentModule: function () {
      return this.$store.state.currentModule
    },
    contentModule: function () {
      return this.$store.state.currentModule.content ? this.$store.state.currentModule.content : {}
    },
    isStudio: function () {
      return (this.contentModule && this.contentModule.studio)
    },
  },
  created() {
    this.$store.dispatch("getCurrentModule", {
      url: "dashboard",
      that: this,
    })
  }
};
</script>
